import React from 'react'
import FileSaver from 'file-saver'
import * as XLSX from "xlsx"

import './App.css';

const lz = n => {
	if(n < 10)
		return "0" + n
	return "" + n
}

const print_date = () => {
	const now = new Date()
	
	return "" + now.getFullYear() + "_" + lz(now.getMonth()+1) +
		"_" + lz(now.getDate()) + "_" + lz(now.getHours()) +
		"_" + lz(now.getMinutes()) + "_" + lz(now.getSeconds())
}

const check_if_seq = (s) => {
	for(let i = 0; i < s.length; ++i){
		const c = s.charAt(i)

		if(c!=="A"&&c!=="C"&&c!=="G"&&c!=="U"){
			return false
		}
	}

	return true;
}



class App extends React.Component {
	state = {
		selectedFile: null,
		waiting: ""
	}

	onFileChange = e => {
		//console.log(e.target.files[0])
		this.setState({ selectedFile: e.target.files[0] })
	}




	get_file_response = payl => {
		const requestOptions = {
			method: 'POST',
                	headers: { 'Content-Type': 'application/json' },
               		body: JSON.stringify(payl)
        	};

        	fetch('https://api.shannon.sedna.cs.put.poznan.pl', requestOptions)
        		.then(response => response.blob())
                	.then(blob => {FileSaver.saveAs(blob, "results_"+print_date()+".xlsx");this.setState({waiting: ""})})
			.catch(err => {this.setState({waiting:""})})
	}






	process_fasta = e => {
		let text = e.target.result
		text = text.split(/\r?\n/)

		const payl = []
		let id = null
		let counter = 0

		const dic = {}

		for(let line of text){
			line = line.trim()
			if(line.length === 0 || line.charAt(0) === ";")
				continue
			if(line.charAt(0) === ">"){
				id=line.substring(1)
				continue
			}

			if(!id){
				id = "custom_seq" + (++counter)
			}

			if(!dic[line]){
				payl.push({id, seq:line})
				dic[line] = true
			}

			id = null
		}

		this.get_file_response(payl)
	}

	process_xlsx = e => {
		const bstr = e.target.result;
		const wb = XLSX.read(bstr, { type: "binary" });

		const payl = []

		const dic = {}

		for(const wsname of wb.SheetNames){
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_csv(ws, { header: 0 }).replaceAll('"','').split(/\r?\n/);

			for(let line of data){
				line = line.trim()
				line = line.split(',')
				//console.log(line)

				const len = line.length

				if(len < 3)
					continue
			
				let seq = ""

				for(let i = 0; i < 3; ++i){
					line[i] = line[i].trim()

					if(check_if_seq(line[i])){
						seq += line[i]
					}
				}
				//console.log(seq)
				if(seq === "")
					continue

				let id = line[len-2].trim() + "," + line[len-1].trim()

				if(id === "")
					continue

				if(dic[seq])
					continue
				dic[seq] = true
				payl.push({seq,id})

			}
		
		}

		//console.log(payl)
		this.get_file_response(payl)
		//console.log(payl)
		//const wsname = wb.SheetNames[0];
	}










	onFileUpload = e => {
		if (!this.state.selectedFile){
			alert("No file selected!")
			return
		}



		const file = this.state.selectedFile
		const name = file.name
		this.setState({waiting: name})
		//const payl = [{seq:"AAAAAAAAAAAAACCCCCCCCCCCCCCCCCCUUUUUUUGGGGGGGGGGGGGGGGG", id:"6667"}]


		const reader = new FileReader()
		
		if(name.endsWith(".xlsx") || name.endsWith(".xlsx")){//Excel
			reader.onload = this.process_xlsx
			reader.readAsBinaryString(file)

		}
		else{//fasta
			reader.onload = this.process_fasta
			reader.readAsText(file)
		}

	}

	render = () => (
		<div className="App">
    			<h1>Calculate free energy and Shannon entropy</h1>
		 	<input type="file" onChange={this.onFileChange} accept=".xlsx, .xls, .fasta, .fna, .ffn, .faa, .frn, .fa" /><br /> 
			<br />
                	<button onClick={this.onFileUpload} disabled={this.state.waiting !== ""}> 
				Upload! 
			</button>
			<br />
			<br />
			<p style={{color:'red'}} hidden={this.state.waiting===""}>Currently processing: {this.state.waiting} Please wait!</p>
		</div>
  	)
}

export default App;
